import React from 'react';

import { Button } from '@change-corgi/design-system/components/actions';
import { Avatar } from '@change-corgi/design-system/components/media';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { useCreateVideo } from './hooks/useCreateVideo';

export function CreateVideo(): React.JSX.Element | null {
	const {
		data: { username, userHasSigned },
		actions: { createVideoClickHandler },
	} = useCreateVideo();

	const buttonAsk = userHasSigned ? `${username}, can you share why you care?` : 'Sign and share why you care!';

	return (
		<Flex
			data-testid="create-video-ask"
			sx={{
				flexShrink: 0,
				height: 244,
				width: 170,
				px: 16,
				gap: 16,
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				background: 'primary-greyBackground',
				borderRadius: 'standard',
			}}
		>
			<Avatar alt="avatar" size={60} src="https://assets.change.org/photos/5/rm/sw/fqRMSWIetwCbMdr-400x400-noPad.jpg" />

			<Text
				as="p"
				sx={{
					textAlign: 'center',
				}}
			>
				{buttonAsk}
			</Text>

			{userHasSigned && (
				<Button
					size="small"
					onClick={createVideoClickHandler}
					data-testid="create-video-button"
					variant="secondary"
					sx={{ width: '100%' }}
				>
					Add your voice
				</Button>
			)}
		</Flex>
	);
}
