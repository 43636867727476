import { useMemo } from 'react';

import { useDecisionMakers } from '../useDecisionMakers';
import { useMediaHits } from '../useMediaHits';
import { useUpdates } from '../useUpdates';
import { useVotersPercentage } from '../useVotersPercentage';

type Result = ModelHookResult<{
	sectionIsShown: boolean;
	decisionMakers: {
		isShown: boolean;
		count: number;
		first: { displayName: string };
	};
	mediaHits: {
		isShown: boolean;
		count: number;
	};
	updates: {
		isShown: boolean;
		count: number;
	};
	voters: {
		isShown: boolean;
		percentage: number;
	};
}>;

export const useContentSummary = (): Result => {
	const { data: voters } = useVotersPercentage();
	const { data: updates } = useUpdates();
	const { data: decisionMakers } = useDecisionMakers();
	const { data: mediaHits } = useMediaHits();

	return useMemo(() => {
		return {
			data: {
				sectionIsShown: decisionMakers.isShown || mediaHits.isShown || updates.isShown || voters.isShown,
				decisionMakers,
				mediaHits,
				updates,
				voters,
			},
			actions: {},
		};
	}, [decisionMakers, mediaHits, updates, voters]);
};
